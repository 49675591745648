import React from "react";
import { Link } from "react-router-dom";





export default function Services(){
    return (<div id="__next"> <div class="container mx-auto px-5 hidden lg:block">
    <div class="grid grid-cols-3 gap-0.5">
        <div class="col-span-1 py-3 flex items-center justify-center">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="bi bi-geo-alt text-primary text-4xl mr-3 text-[#E10606]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <g id="Location_On">
                    <g>
                        <path d="M12,21.933a1.715,1.715,0,0,1-1.384-.691L5.555,14.5a7.894,7.894,0,1,1,12.885-.009L13.385,21.24A1.717,1.717,0,0,1,12,21.933ZM11.992,3.066A6.81,6.81,0,0,0,7.414,4.815a6.891,6.891,0,0,0-1.05,9.1l5.051,6.727a.725.725,0,0,0,.584.292h0a.732.732,0,0,0,.586-.292l5.044-6.734A6.874,6.874,0,0,0,12.81,3.113,7.277,7.277,0,0,0,11.992,3.066Z"></path>
                        <path d="M12,12.5A2.5,2.5,0,1,1,14.5,10,2.5,2.5,0,0,1,12,12.5Zm0-4A1.5,1.5,0,1,0,13.5,10,1.5,1.5,0,0,0,12,8.5Z"></path>
                    </g>
                </g>
            </svg>
            <div class="text-start">
                <h6 class="uppercase font-bold">Our Office</h6>
                <a href="https://maps.app.goo.gl/xrApfqSUJxJb7n839">
                    <div class="flex hover:text-[#007EF6] duration-100 flex-col">                                 
                        <span>Plot No. 7, Street No. 17</span>
                        <span class="-mt-1">Industrial Area 13, Sharjah</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-span-1 border-l border-r py-3 flex items-center justify-center">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="bi bi-envelope-open text-primary text-4xl mr-3 text-[#E10606]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <g id="Mail">
                    <path d="M19.435,4.065H4.565a2.5,2.5,0,0,0-2.5,2.5v10.87a2.5,2.5,0,0,0,2.5,2.5h14.87a2.5,2.5,0,0,0,2.5-2.5V6.565A2.5,2.5,0,0,0,19.435,4.065Zm-14.87,1h14.87a1.489,1.489,0,0,1,1.49,1.39c-2.47,1.32-4.95,2.63-7.43,3.95a6.172,6.172,0,0,1-1.06.53,2.083,2.083,0,0,1-1.67-.39c-1.42-.75-2.84-1.51-4.25-2.26-1.14-.6-2.3-1.21-3.44-1.82A1.491,1.491,0,0,1,4.565,5.065Zm16.37,12.37a1.5,1.5,0,0,1-1.5,1.5H4.565a1.5,1.5,0,0,1-1.5-1.5V7.6c2.36,1.24,4.71,2.5,7.07,3.75a5.622,5.622,0,0,0,1.35.6,2.872,2.872,0,0,0,2-.41c1.45-.76,2.89-1.53,4.34-2.29,1.04-.56,2.07-1.1,3.11-1.65Z"></path>
                </g>
            </svg>
            <div class="text-start">
                <h6 class="uppercase font-bold">Email Us</h6>
                <a href="mailto:info@yanaluae.com" class="hover:text-[#007EF6] duration-100">info@yanaluae.com</a>
            </div>
        </div>
        <div class="col-span-1 py-3 flex items-center justify-center">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="bi bi-phone-vibrate text-primary text-4xl mr-3 text-[#E10606]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"></path>
                <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"></path>
            </svg>
            <div class="text-start">
                <h6 class="uppercase font-bold">Call Us</h6>
                <a href="tel:+971565301894" class="hover:text-[#007EF6] duration-100">+971565676222</a>
            </div>
        </div>
    </div>
</div>
<div class="sticky w-screen top-0 z-20">
<nav class="bg-gray-900 flex justify-between items-center pl-5 xyz-in ">
<h1 class="m-0 uppercase">
    <a href="/">
        <img src="/images/logo0.png" class="w-20" alt=""/>
    </a>
</h1>
<ul id="menu" xyz="fade flip-down stagger duration-10 delay-1 ease-out-back" class="gap-4  md:items-center md:flex md:flex-row md:bg-auto bg-gray-900  md:w-auto w-full md:static absolute top-[57px] hidden flex-col items-start md:p-0 p-5 px-7 md:h-auto h-[17.1rem] duration-100 left-0 z-20 uppercase">
    <li class="xyz-nested">
        <Link class="text-white hover:text-[#E60304] duration-150 cursor-pointer" to="/">Home</Link>
    </li>
    <li class="xyz-nested">
        <Link class="text-white hover:text-[#E60304] duration-150 cursor-pointer" to="/about">About</Link>
    </li>
    <li class="xyz-nested">
        <Link class="text-white hover:text-[#E60304] duration-150 cursor-pointer" to="/services">Services</Link>
    </li>
    <li class="xyz-nested">
        <Link class="text-white hover:text-[#E60304] duration-150 cursor-pointer" to="/ourgroups">Our Groups</Link>
    </li>
    <li class="relative xyz-nested md:block hidden md:w-auto w-full">
        <Link class="text-white hover:text-[#E60304] duration-150 cursor-pointer" to="/projects">Projects</Link>
    </li>
    <li class="relative xyz-nested md:hidden md:w-auto w-full">
        <a class="text-white hover:text-[#E60304] duration-150 cursor-pointer" href="/">Projects</a>
        <ul class="md:absolute text-sm static top-full md:pt-5 md:-right-4 md:w-52 w-full  bg-gray-900 py-2 px-4 md:shadow-lg rounded-lg">
            <li class="py-2">
                <a class="text-white hover:text-[#E60304] duration-150 cursor-pointer flex gap-1 items-start" href="/projects/completed">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="shrink-0 mt-0.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M464 128L240 384l-96-96m0 96l-96-96m320-160L232 284"></path>
                    </svg>
                    Completed Projects
                </a>
            </li>
            <li class="py-2">
                <a class="text-white hover:text-[#E60304] duration-150 cursor-pointer flex gap-1 items-start" href="/projects/ongoing">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="shrink-0 mt-0.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
                    </svg>
                    Ongoing Projects
                </a>
            </li>
        </ul>
    </li>
    <li class="md:w-auto w-full md:mt-0 -mt-3">
        <Link to="/contact">
            <button class="bg-[#E60304] md:h-[81px] md:px-6 md:w-auto w-full md:text-center text-left px-2 md:py-0 py-1  uppercase text-white">Contact</button>
        </Link>
    </li>
</ul>
<button class="md:hidden block pr-5 text-3xl text-white">
    <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z" fill="currentColor"></path>
    </svg>
</button>
</nav>
</div><div class="w-full p-5 relative"><h2 class="border-b-2 border-[#E60304] flex 
    items-center"><span class="font-bold roboto-font uppercase bg-[#E60304] text-white p-2">Services</span></h2>
    <ul class="flex flex-col gap-4"><li><h2 class="border-b-2 border-[#E60304] sticky md:top-[81px] top-[57px] 
    bg-white pt-[9px] flex items-center"><span class="font-bold roboto-font uppercase bg-[#E60304] 
    text-white p-2"> 1. Construction Contracting</span></h2><div class="w-full flex flex-col-reverse 
    md:flex-row"><div class="w-full flex flex-col md:w-[65%]"><p class="open-sans-font mt-4 text-justify">YBCC 
    is a core construction company with in-house MEP and Wooden Work registering success in various projects 
    comprises of commercial buildings, warehouses, shops, stores, cafes, restaurants, residential buildings, 
    villas, hotels, hospitals, mosques are our range of services covering the entire spectrum.</p><br/>
    <p class="open-sans-font text-justify">YBCC, the resources and skill set necessary 
    to advise on reining the engineering aspects of projects in order to deliver significant technical and 
    commercial benefits. To deliver this approach we have the support of a full range of a-listed specialists 
    in the field of construction.</p><br/><p class="open-sans-font text-justify">We work
    on high-rise construction projects in the UAE. Our team has the experience and expertise to support a 
    comprehensive range of projects in these areas. At YBCC, we voluntarily deploy a value-engineering approach 
    to meet the needs of clients, led by an in-house team of dedicated professionals. Our review-and-analysis 
    approach ensures that project plans are fully realizable; maximising opportunities for cost control while 
    at the same time ensuring that projects are delivered on time and to the specified standards.</p></div>
    <div class="w-full flex justify-center md:w-[35%]"><img src="/images/contract.jpg" class="w-[70%] 
    object-contain" alt=""/></div></div></li><li><h2 class="border-b-2 border-[#E60304] sticky md:top-[81px] 
    top-[57px] bg-white pt-[9px] flex items-center"><span class="font-bold roboto-font uppercase bg-[#E60304] 
    text-white p-2"> 2. Electrical Fitting Contracting</span></h2><div class="w-full flex flex-col-reverse 
    md:flex-row"><div class="w-full flex flex-col md:w-[65%]"><ul class="flex flex-col list-decimal list-inside 
    mt-2 open-sans-font"><li> Erection and commissioning of Electrical Substations.</li><li> Supply and 
    installation of Diesel Generating Stations including L.V. Switchgears, Motor Control Centres and 
    Specialized Panels.</li><li>Low Voltage &amp; Low Current Installations like Fire Alarm and Emergency 
    Lighting Systems, Security System, Access Control System, Public Address System, CCTV, MATA/SMATV System, 
    Structured Cabling System, Computer Installations and Master Clock System.</li><li>Building Management 
    System.</li><li>Dimming/Lighting Controls for residential and commercial projects.</li><li>Lightning 
    Protection</li><li>Street Light Installations</li></ul></div><div class="w-full flex 
    justify-center md:w-[35%]"><img src="/images/electric.jpg" class="w-[60%]" alt=""/></div></div></li><li>
    <h2 class="border-b-2 border-[#E60304] sticky md:top-[81px] top-[57px] bg-white pt-[9px] flex items-center">
    <span class="font-bold roboto-font uppercase bg-[#E60304] text-white p-2"> 3. Plumbing, Drainage, Fire 
    Fighting and LPG Syste</span></h2><div class="w-full flex flex-col-reverse md:flex-row">
    <div class="w-full flex flex-col md:w-[65%]"><ul class="flex flex-col list-decimal list-inside mt-2 
    open-sans-font"><li>  Hot and Cold Water System including Steam System.</li><li> Ducted Split Unit System.
    </li><li>Drainage System.</li><li>Pumping 
    Stations.</li><li>Sewage Treatment Plants.</li><li>Fire Fighting 
    Installations.</li><li>Sprinkler Installations.</li><li>CO2, Halogen &amp; FM 200 Installations.</li>
    <li>LPG Gas System.</li><li>Life Safety Installations.</li><li>Compressed Air System.</li>
    <li>Solar System.</li></ul></div><div class="w-full flex justify-center md:w-[35%]">
    <img src="/images/plumber.jpg" class="md:w-[80%] w-3/4 object-contain" alt=""/></div></div></li>
    <li class="md:-mb-8"><h2 class="border-b-2 border-[#E60304] sticky md:top-[81px] top-[57px] 
    bg-white pt-[9px] flex items-center"><span class="font-bold roboto-font uppercase bg-[#E60304] text-white 
    p-2"> 4. Metal &amp; Iron Works</span></h2><div class="w-full flex flex-col-reverse md:flex-row">
    <div class="w-full flex flex-col md:w-[65%]"><p class="open-sans-font mt-4 text-justify">Our metal and 
    iron works service offers top-notch custom fabrication and installation of high-quality metalwork for both 
    commercial and residential clients. We are committed to delivering exceptional work that is tailored to 
    your specific needs and requirements. From custom railings and stairs to structural steel and ornamental
    ironwork, our team of experienced professionals can handle any project, big or small. We take pride in 
    our attention to detail and use the latest tools and techniques to ensure a flawless finish. Whether 
    you&#x27;re looking to add a unique touch to your home or need custom metalwork for your business, we are 
    here to bring your ideas to life.</p></div><div class="w-full flex justify-center md:w-[35%] my-4">
    <img src="/images/metal.jpg" class="w-1/2 object-contain" alt=""/></div></div></li><li class="">
    <h2 class="border-b-2 border-[#E60304] sticky md:top-[81px] top-[57px] bg-white pt-[9px] flex items-center">
    <span class="font-bold roboto-font uppercase bg-[#E60304] text-white p-2"> 5. Glass Work</span></h2><div class="w-full flex flex-col-reverse md:flex-row"><div class="w-full flex flex-col md:w-[65%]">
    <p class="open-sans-font mt-4 text-justify">Outfit your home or office with all products of aluminum and 
    glass, sheer curtain Dubai. Make gorgeous entryways or shops that reflect your sense of style. Your home 
    or company will transform with a wide range of colors, styles, and accessory options. We are providing a 
    full line of services for homes and buildings in Dubai. The structures that societies create reflect their 
    culture and style. Commercial structures are inspiring and impactful buildings that define modern culture. 
    Although, our Glass and Aluminum Company in UAE expert team provide precise and comprehensive solutions for 
    all commercial glass and aluminum projects. You can rely on us to provide the highest quality glazing 
    services. However, we provide all types of glass and aluminum product services and sheer curtains in Dubai.
    </p></div><div class="w-full flex justify-center md:w-[35%] my-4"><img src="/images/gw.jpg" 
    class="w-1/2 object-contain" alt=""/></div></div>
   </li></ul>{/*<a href="/Yanalprofile.pdf" download="" 
    class="px-3 py-1 bg-[#007EF6] text-white text-center">Download YBCC Profile</a>*/}</div><div class="w-full flex gap-4 flex-col md:flex-row p-5 bg-gray-900">
                <div class="w-full flex gap-4 flex-col md:w-1/2">
                    <a href="#">
                        <img src="" class="w-20" alt=""/>
                    </a>
                    <p class="text-gray-300">YBCC is one of the fastest growing group in UAE with specialization in construction, MEP, carpentry and aluminium glass services.</p>
                    <ul class="flex flex-col gap-2 text-gray-300">
                        <li>
                            <a href="https://maps.app.goo.gl/xrApfqSUJxJb7n839" rel="nofollow" class="flex items-start gap-1">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="shrink-0 mt-1.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z"></path>
                                </svg>
                                Plot No. 7, Street No. 17 Insustrial Area 13, Sharjah
                            </a>
                        </li>
                        <li>
                            <a href="tel:+971565676222" class="flex items-start gap-1">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="shrink-0 mt-1.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z"></path>
                                </svg>
                                +971565676222
                            </a>
                        </li>
                        <li>
                            <a href="tel:+971565676222 " class="flex items-start gap-1">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="shrink-0 mt-1.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M20 3h-2a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2z"></path>
                                    <path d="M16 4h-11a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h11"></path>
                                    <path d="M12 8h-6v3h6z"></path>
                                    <path d="M12 14v.01"></path>
                                    <path d="M9 14v.01"></path>
                                    <path d="M6 14v.01"></path>
                                    <path d="M12 17v.01"></path>
                                    <path d="M9 17v.01"></path>
                                    <path d="M6 17v.01"></path>
                                </svg>
                                +971-65343689
                            </a>
                        </li>
                        <li>
                            <a href="/" class="flex items-start gap-1">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="shrink-0 mt-1.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M19 9h-1V4H8v5h-.78C6.67 8.39 5.89 8 5 8c-1.66 0-3 1.34-3 3v7c0 1.66 1.34 3 3 3 .89 0 1.67-.39 2.22-1H22v-8c0-1.66-1.34-3-3-3zM6 18c0 .55-.45 1-1 1s-1-.45-1-1v-7c0-.55.45-1 1-1s1 .45 1 1v7zm4-12h6v3h-6V6zm10 12H8v-7h11c.55 0 1 .45 1 1v6z"></path>
                                    <circle cx="15" cy="13" r="1"></circle>
                                    <circle cx="18" cy="13" r="1"></circle>
                                    <circle cx="15" cy="16" r="1"></circle>
                                    <circle cx="18" cy="16" r="1"></circle>
                                    <path d="M9 12h4v5H9z"></path>
                                </svg>
                                +971-65344921
                            </a>
                        </li>
                        <li>
                            <a href="mailto:info@yanaluae.com" class="flex items-start gap-1">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="shrink-0 mt-1.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                                </svg>
                                info@yanaluae.com
                            </a>
                        </li>
                    </ul>
                    <ul class="flex flex-wrap gap-2">
                        <li class="flex items-center">
                            <a href="/" rel="nofollow">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-[#007EF6] text-2xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                                </svg>
                            </a>
                        </li>
                        <li class="flex items-center">
                            <a href="/">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="text-[#007EF6] text-3xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"></path>
                                </svg>
                            </a>
                        </li>
                        <li class="flex items-center">
                            <a href="/" rel="nofollow">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="text-[#007EF6] text-3xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="w-full flex gap-2 flex-col md:w-1/2">
                    <div class="w-full flex gap-4 flex-col md:flex-row">
                        <div class="w-full md:w-1/2">
                            <h3 class="font-bold roboto-font uppercase text-gray-300 text-lg">Quick Links</h3>
                            <ul class="flex flex-col gap-2 text-gray-300 mt-1">
                                <li>
                                    <Link class="flex items-start gap-1 hover:text-[#E60304]" to="/about">About</Link>
                                </li>
                                <li>
                                    <Link class="flex items-start gap-1 hover:text-[#E60304]" to="/contact">Contact</Link>
                                </li>
                                <li>
                                    <Link class="flex items-start gap-1 hover:text-[#E60304]" to="/ourgroups">Our Groups</Link>
                                </li>
                            </ul>
                        </div>
                        <div class="w-full md:w-1/2">
                            <h3 class="font-bold roboto-font uppercase text-gray-300 text-lg">Actual Links</h3>
                            <ul class="flex flex-col gap-2 text-gray-300 mt-1">
                                <li>
                                <Link class="flex items-start gap-1 hover:text-[#E60304]" to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link class="flex items-start gap-1 hover:text-[#E60304]" to="/projects/completed">Completed Projects</Link>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div></div> 




);
}

