import { BrowserRouter as Router, Switch, Route, Link, Routes, BrowserRouter} from "react-router-dom";
import Header from "./component/header";
import Footer from "./component/footer";
import Home from "./component/home";
import About from "./component/about";
import Services from "./component/services";
import Group from "./component/group";
import Projects from "./component/projects";
import Contact from "./component/contact";
import Completed from "./component/completed";



function App() {
  return (
    <Router>
    <Routes>    
    <Route path="/" element={<Home/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="/services" element={<Services/>}/>
    <Route path="/ourgroups" element={<Group/>}/>
    <Route path="/projects" element={<Projects/>}/>
    <Route path="/projects/completed" element={<Completed/>}/>
       <Route path="/contact" element={<Contact/>}/>

</Routes>
 </Router>
   


   
     
   
  );
}

export default App;
